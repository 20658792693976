<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-6">
            <img src="@/assets/images/freightShipping.jpg" width="100%" height="800px" class="img img-responsive img-rounded">
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <h2 style="color: #000000;"><b>LET'S GET STARTED</b></h2>
            <h2 style="color: #000000;">Tell us about yourself</h2>
            <br>
            <a :href="VUE_APP_FMS_BUSINESS_REGISTER" target="_blank">
              <div class='panel img-rounded panel-login panel-hover'>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-md-9">
                      <h3>Businesses</h3>
                      <p>I am looking for a truck to move my goods.</p>
                    </div>
                    <div class="col-md-2">
                      <img src="@/assets/images/arrow-right.png" width="100px" height="100px">
                    </div>
                  </div>
                </div>
              </div>
            </a>

            <a :href="VUE_APP_FMS_INDIVIDUAL_REGISTER" target="_blank">
              <div class='panel img-rounded panel-login panel-hover'>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-md-9">
                      <h3>Individuals</h3>
                      <p>I am looking for loads to haul.</p>
                    </div>
                    <div class="col-md-2">
                      <img src="@/assets/images/arrow-right.png" width="100px" height="100px">
                    </div>
                  </div>
                </div>
              </div>
            </a>

            <a :href="VUE_APP_LOAD_BOARD_BROKER_REGISTER" target="_blank">
              <div class='panel img-rounded panel-login panel-hover'>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-md-9">
                      <h3>Brokers</h3>
                      <p>I am looking for flexible capacity.</p>
                    </div>
                    <div class="col-md-2">
                      <img src="@/assets/images/arrow-right.png" width="100px" height="100px">
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>

    <FooterComponent/>
  </div>
</template>

<script type="text/javascript">
  import FooterComponent from "@/components/FooterComponent.vue";

  export default {
    components: {
      FooterComponent
    },

    data() {
      return {
        VUE_APP_FMS_BUSINESS_REGISTER: process.env.VUE_APP_FMS_BUSINESS_REGISTER,
        VUE_APP_FMS_INDIVIDUAL_REGISTER: process.env.VUE_APP_FMS_INDIVIDUAL_REGISTER
      }
    },

    computed: {

    },

    created() {

    },

    methods: {

    }
  };
</script>

<style type="text/css" scoped>
  .panel-login{
    background-color: #f5f4f2;
    color: #000000;
  }

  .panel-hover:hover {
    background-color: #000000;
    color: #ffffff;
  }
</style>
