<template>
  <div class="panel footer-panel">
    <div class="panel-body">
      <div class="row">
        <div class="col-md-1">
          <img src="@/assets/logo.png" width="160" height="55">&nbsp;
        </div>
        <div class="col-md-11">
          <br>
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          <br>
          <p>We’re making the trucking and logistics industry more fair and accessible through transparent financing, payments and software.</p>
        </div>

        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
          <h3>Support</h3>
          <p>Help Center</p>
          <p><span class="glyphicon glyphicon-phone-alt"></span> Tel +263 (864) 428 8897</p>
          <p><span class="glyphicon glyphicon-phone"></span> Cell <a href="tel:263715861380">+263 715 861 380</a></p>
          <p>Email <a href="mailto:support@nelspruitfreightboard.com">support@nelspruitfreight.com</a></p>
        </div>

        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
          <h3>Solutions</h3>
          <ul class="list-unstyled">
            <li><router-link to="shippers" class="list-item">Shippers</router-link></li>
            <li><router-link to="carriers" class="list-item">Carriers</router-link></li>
            <li><router-link to="brokers" class="list-item">Brokers</router-link></li>
          </ul>
        </div>

        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
          <h3>Company</h3>
          <ul class="list-unstyled">
            <li><router-link to="company" class="list-item">About us</router-link></li>
            <li><router-link to="resources" class="list-item">Resources</router-link></li>
            <li><a href="#" class="list-item">Partnerships</a></li>
          </ul>
        </div>

        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
          <h3>Connect with us</h3>
          <div class="row">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <a :href="'//' + linkedin" target="_blank" class="list-item"><img src="@/assets/images/linkedin.png" height="20" width="20"></a>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <a :href="'//' + twitter" target="_blank" class="list-item"><img src="@/assets/images/twitter.png" height="20" width="20"></a>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <a :href="'//' + facebook" target="_blank" class="list-item"><img src="@/assets/images/facebook.png" height="20" width="20">
              </a>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <a :href="'//' + instagram" target="_blank" class="list-item"><img src="@/assets/images/instagram.png" height="20" width="20"></a>
            </div>
          </div>
        </div>
      </div>
      <br><br>

      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          <p class="list-item">© {{ date }} Nelspruit Freight Team | All rights reserved.</p>
        </div>
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
          <router-link to="terms-of-use" style="color: #FFF;"> <img src="@/assets/images/securityPolicy.png" height="20" width="20"> Terms and Conditions</router-link>
        </div>
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
          <router-link to="privacy" style="color: #FFF;"><img src="@/assets/images/privacyPolicy.png" height="20" width="20"> Privacy Policy</router-link>
        </div>
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
          <router-link to="security" style="color: #FFF;"><img src="@/assets/images/privacy.png" height="20" width="20"> Information Security</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default{
    data: function(){
      return {
        date: new Date().getFullYear(),
        linkedin: 'www.linkedin.com/company/nelspruitfreight',
        twitter: 'www.twitter.com/nelspruitfreight',
        facebook: 'www.facebook.com/nelspruitfreight',
        instagram: 'www.instagram.com/nelspruitfreight'
      }
    }
  }
</script>

<style type="text/css">
  .footer-panel{
    background-color: #000000;
    color: #ffffff;
  }

  .list-item{
    color: #ffffff;
  }
</style>
