import { render, staticRenderFns } from "./ShipperBottomNavigation.vue?vue&type=template&id=bc8abb40&scoped=true&"
var script = {}
import style0 from "./ShipperBottomNavigation.vue?vue&type=style&index=0&id=bc8abb40&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc8abb40",
  null
  
)

export default component.exports