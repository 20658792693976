<template>
  <div>
    <nav class="navbar navbar-default navbar-light" role="navigation">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>    
        <a class="navbar-brand">
          <router-link to="/" class="navbar-brand" data-toggle="collapse" data-target=".in">
            <img src="@/assets/logo-2.png" class="img img-responsive img-rounded center-block logo" align="Nelspruit Freight">
          </router-link>
        </a>
      </div>
      <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav navbar-left">
          <li id="mainList">
              <router-link to="shippers" data-toggle="collapse" data-target=".in"><h4 style="color: #ffffff;"><strong><b>Shippers</b></strong></h4></router-link>
          </li>
          <li id="mainList">
            <router-link to="brokers" data-toggle="collapse" data-target=".in"><h4 style="color: #ffffff;"><strong><b>Brokers</b></strong></h4></router-link>
          </li>
          <li id="mainList">
            <router-link to="carriers" data-toggle="collapse" data-target=".in"><h4 style="color: #ffffff;"><strong><b>Carriers</b></strong></h4></router-link>
          </li>
          <li id="mainList">
            <router-link to="resources" data-toggle="collapse" data-target=".in"><h4 style="color: #ffffff;"><strong><b>Resources</b></strong></h4></router-link>
          </li>
          <li id="mainList">
            <router-link to="company" data-toggle="collapse" data-target=".in"><h4 style="color: #ffffff;"><strong><b>Company</b></strong></h4></router-link>
          </li>
          <li id="mainList">
            <router-link to="contact" data-toggle="collapse" data-target=".in"><h4 style="color: #ffffff;"><strong><b>Support</b></strong></h4></router-link>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-center">
          
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li>
            <router-link to="login" data-toggle="collapse" data-target=".in"><button class="btn btn-login"><strong><b>Login</b></strong> <span class="glyphicon glyphicon-log-in"></span></button></router-link>
          </li>
          <li>
            <router-link to="register" data-toggle="collapse" data-target=".in"><button class="btn btn-primary btn-getstarted"><strong><b>Get started</b></strong></button></router-link>
          </li>
          <li>
            <a :href="VUE_APP_TMS" target="_blank" data-toggle="collapse" data-target=".in"><button class="btn btn-login"><strong><b>Login to TMS</b></strong></button></a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script type="text/javascript">
  export default {
    data: function(){
      return {
        VUE_APP_TMS: process.env.VUE_APP_TMS
      }
    }
  };
</script>

<style type="text/css" scoped>
  .navbar{
    background: #ffffff;
  }
  .navbar-brand {
    padding-top: 2px; /* makes logo fit in navbar*/
  }

  .test{
    margin: 2px;
  }

  .btn-login {
    /*width: 85px;*/
    /*height: 35px;*/
    text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #000000;
    color: #ffffff;
  }

  .btn-getstarted {
     text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #ffffff; 
    color: #000000;
  }

  .logo {
    display: block;
    height: 55px;
    width: 165px;
    margin-top: 5px
  }

  @media(max-width: 768px){
    .navbar-brand img{
      width: 100px;
      max-height: 60px;
      /*padding-bottom: 20px;*/
      padding-bottom: 20px;
    }
  }
</style>